/**
 * @see https://www.radix-ui.com/primitives/docs/components/dropdown-menu#dropdown-menu
 * @see https://ui.shadcn.com/docs/components/dropdown-menu
 */
"use client"

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { VariantProps, cva } from "class-variance-authority"
import { forwardRef, memo } from "react"

import { cn } from "../utils"

// Note: There are components from Radix that are not re-exported here because they are not used yet.
// They can re-exported in the future when they are actually used.

/**
 * Displays a menu to the user — such as a set of actions or functions — triggered by a button.
 *
 * @example
 * ```tsx
 * <DropdownMenu>
 *   <DropdownMenuTrigger>
 *       Trigger
 *   </DropdownMenuTrigger>
 *   <DropdownMenuPortal>
 *     <DropdownMenuContent>
 *       <DropdownMenuItem>Item #1</DropdownMenuItem>
 *       <DropdownMenuItem>Item #2</DropdownMenuItem>
 *       <DropdownMenuItem>Item #3</DropdownMenuItem>
 *       <DropdownMenuArrow />
 *     </DropdownMenuContent>
 *   </DropdownMenuPortal>
 * </DropdownMenu>
 * ```
 * @see https://web-docs.spatial.io/components/dropdown-menu
 */
const DropdownMenu = DropdownMenuPrimitive.Root
const DropdownMenuTrigger = memo(({ className, ...props }: DropdownMenuPrimitive.DropdownMenuTriggerProps) => {
  return <DropdownMenuPrimitive.Trigger className={cn("focus:outline-none", className)} {...props} />
})
const DropdownMenuPortal = DropdownMenuPrimitive.Portal
const DropdownMenuGroup = DropdownMenuPrimitive.Group

/*****************************************************
 * DropdownMenuContent
 *****************************************************/
const SLIDE_ANIMATION = cn(
  "data-[side=top]:data-[state=closed]:slide-out-to-bottom-4 data-[side=top]:data-[state=open]:slide-in-from-bottom-4",
  "data-[side=bottom]:data-[state=closed]:slide-out-to-top-4 data-[side=bottom]:data-[state=open]:slide-in-from-top-4",
  "data-[side=left]:data-[state=closed]:slide-out-to-right-4 data-[side=left]:data-[state=open]:slide-in-from-right-4",
  "data-[side=right]:data-[state=closed]:slide-out-to-left-4 data-[side=right]:data-[state=open]:slide-in-from-left-4"
)
const ZOOM_ANIMATION = "data-[state=closed]:zoom-out-90 data-[state=open]:zoom-in-90"
const FADE_ANIMATION =
  "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"

export const dropdownMenuContentVariants = cva(
  cn(
    // Base styles
    "z-popover min-w-[8rem] rounded-md bg-popover p-1 text-sm text-foreground shadow-[0_4px_6px_-1px_rgb(0,0,0,0.2)]"
  ),
  {
    variants: {
      animation: {
        default: cn(FADE_ANIMATION, ZOOM_ANIMATION),
        slideIn: cn(FADE_ANIMATION, SLIDE_ANIMATION),
        fadeOnly: FADE_ANIMATION,
        none: "",
      },
    },
    defaultVariants: { animation: "default" },
  }
)

export type DropdownMenuContentProps = DropdownMenuPrimitive.DropdownMenuContentProps &
  VariantProps<typeof dropdownMenuContentVariants>
/**
 * Note: When using animation `slideIn` make sure that
 * there is sufficient `sideOffset` so that the
 * so click does not register on menu, this causes an
 * unwanted flickering effect
 */
const DropdownMenuContent = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  DropdownMenuContentProps
>(({ className, sideOffset = 2, animation, ...props }, ref) => {
  return (
    <DropdownMenuPrimitive.Content
      className={cn(dropdownMenuContentVariants({ animation }), className)}
      ref={ref}
      sideOffset={sideOffset}
      {...props}
    />
  )
})
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

/*****************************************************
 * DropdownMenuItem
 *****************************************************/
const DropdownMenuItem = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  DropdownMenuPrimitive.DropdownMenuItemProps
>(({ className, ...props }, ref) => {
  return (
    <DropdownMenuPrimitive.Item
      ref={ref}
      className={cn(
        "relative flex select-none items-center rounded-sm px-2 py-1.5 outline-none transition-colors data-[disabled]:pointer-events-none data-[highlighted]:cursor-pointer data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50",
        className
      )}
      {...props}
    />
  )
})
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

/*****************************************************
 * DropdownMenuCheckboxItem
 *****************************************************/
const DropdownMenuCheckboxItem = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  DropdownMenuPrimitive.DropdownMenuCheckboxItemProps
>(({ className, ...props }, ref) => {
  return (
    <DropdownMenuPrimitive.CheckboxItem
      ref={ref}
      className={cn(
        "relative flex select-none items-center rounded-sm px-2 py-1.5 outline-none transition-colors data-[disabled]:pointer-events-none data-[highlighted]:cursor-pointer data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50",
        className
      )}
      {...props}
    />
  )
})
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName

/*****************************************************
 * DropdownMenuArrow
 *****************************************************/
/**
 *  NOTE: Sometimes arrow doesn't show up
 */
const DropdownMenuArrow = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Arrow>,
  DropdownMenuPrimitive.DropdownMenuArrowProps
>(({ className, ...props }, ref) => {
  return <DropdownMenuPrimitive.Arrow ref={ref} className={cn("fill-popover", className)} {...props} />
})
DropdownMenuArrow.displayName = DropdownMenuPrimitive.Arrow.displayName

/*****************************************************
 * DropdownMenuSeparator
 *****************************************************/
const DropdownMenuSeparator = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator ref={ref} className={cn("my-1 h-px bg-border", className)} {...props} />
))
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName

export {
  DropdownMenu,
  DropdownMenuGroup,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuArrow,
}
