import { Slot } from "@radix-ui/react-slot"
import { VariantProps, cva } from "class-variance-authority"
import { forwardRef } from "react"

import { BaseComponentProps } from "../types"
import { cn } from "../utils/cn"

const DEFAULT_ELEMENT = "h2"

export const headingVariants = cva("font-heading", {
  variants: {
    size: {
      h1: "text-h1",
      h2: "text-h2",
      h3: "text-h3",
      h4: "text-h4",
      h5: "text-h5",
      m1: "text-m1",
      m2: "text-m2",
      m3: "text-m3",
    },
    weight: {
      black: "font-black",
      bold: "font-bold",
      demibold: "font-demibold",
    },
    textAlign: {
      left: "text-left",
      center: "text-center",
    },
  },
  defaultVariants: { size: DEFAULT_ELEMENT, weight: "demibold" },
})

export type HeadingProps = VariantProps<typeof headingVariants> & BaseComponentProps

/**
 * Displays heading text.
 *
 * @example
 * // Render <h1> HTML element with the default (h2) style.
 * <Heading asChild><h1>Heading</h1></Heading>
 *
 * // Change the style using the `size` and `weight` props. Note that this still renders an `h2` element.
 * <Heading size="h1" weight="black">Heading</Heading>
 *
 * // Pass classnames, Tailwind classes will be merged in the correct order automatically
 * <Heading size="h1" weight="black" className="text-center px-2">Heading</Heading>
 *
 * // Pass intrinsic HTML attributes using `asChild`. This creates a more ergonomic API (better prop auto-completion)
 * <Heading asChild weight="black"><h2 id="foo">Heading</h2></Heading>
 *
 * @see https://web-docs.spatial.io/components/heading
 */
export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => {
  const { className, size, weight: _, textAlign, asChild = false, ...rest } = props
  const weight = props.weight ?? (size === "m1" || size === "m2" || size === "m3" ? "black" : "demibold")
  const Comp = asChild ? Slot : DEFAULT_ELEMENT
  return <Comp ref={ref} className={cn(headingVariants({ size, weight, textAlign, className }))} {...rest} />
})

Heading.displayName = "Heading"
