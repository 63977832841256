import { Slot } from "@radix-ui/react-slot"
import { forwardRef } from "react"

import { BaseComponentProps } from "../types"
import { cn } from "../utils"

/**
 * Centered container that limits max width and applies padding depending on screen size
 */
export const Container = forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<"div"> & BaseComponentProps>(
  ({ asChild = false, className, ...rest }, ref) => {
    const Comp = asChild ? Slot : "div"
    return (
      <Comp
        ref={ref}
        className={cn("mx-auto w-full max-w-screen-4xl", "px-3 sm:px-6 md:px-12 lg:px-[5.75rem]", className)}
        {...rest}
      />
    )
  }
)

Container.displayName = "Container"
