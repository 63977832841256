import Link from "next/link"
import { memo } from "react"

import { ReactComponent as EditIcon } from "@spatialsys/assets/icons/material-filled/edit.svg"
import { UserData, getSubscriptionFromUser } from "@spatialsys/js/sapi/sapi/users"
import { getPlayerColor } from "@spatialsys/js/util/player-colors"
import { Heading, Text } from "@spatialsys/web/ui"

import { AvatarIcon } from "../avatar-icon/avatar-icon"
import { FadeOnHover } from "../avatar-icon/fade-on-hover/fade-on-hover"

export const ProfileDropdownHeader = memo(function ProfileDropdownHeader({ user }: { user: UserData }) {
  const {
    profilePicURL,
    displayName,
    userSocialProfile,
    appearanceCustomization: { profileColor },
  } = user
  const isPlus = Boolean(getSubscriptionFromUser(user))

  return (
    <div className="mb-3 grid max-w-full grid-cols-[auto_minmax(0,1fr)] gap-7">
      <div className="relative h-16 w-16">
        <Link href={`/@${userSocialProfile.username}?edit=true`}>
          <FadeOnHover overlay={<EditIcon className="text-white" />}>
            <AvatarIcon
              profilePicUrl={profilePicURL}
              className="h-16 w-16 shadow-sm"
              placeholderClassName="w-3/4 h-3/4"
              applyPlayerColorToPlaceholder
              playerColor={getPlayerColor(profileColor)}
              altText={`${displayName}'s Avatar`}
            />
          </FadeOnHover>
        </Link>
        {isPlus && (
          <div className="text-heading absolute -right-2 bottom-0 rounded-3xl bg-blue px-1.5 py-[5px] text-2xs font-demibold text-white shadow-sm">
            Plus
          </div>
        )}
      </div>

      <div className="flex flex-col justify-center text-foreground">
        <Heading size="h4" weight="bold" asChild className="overflow-hidden text-ellipsis">
          <h3>{displayName}</h3>
        </Heading>
        <Text size="sm" className="overflow-hidden text-ellipsis text-muted-foreground">
          @{userSocialProfile.username}
        </Text>
      </div>
    </div>
  )
})
