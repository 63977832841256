"use client"

import Link from "next/link"
import { useRouter } from "next/navigation"

import { ReactComponent as CheckIcon } from "@spatialsys/assets/icons/lucide/check.svg"
import { ReactComponent as ChevronsUpDownIcon } from "@spatialsys/assets/icons/lucide/chevrons-up-down.svg"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
  Heading,
} from "@spatialsys/web/ui"

const items = [
  { label: "Creator Toolkit", href: "/toolkit" },
  { label: "Spatial Studio", href: "/studio" },
  { label: "Back to Spatial.io", href: "/" },
]

type NavbarDropdownProps = {
  activeHref: string
}

export function NavbarDropdown({ activeHref }: NavbarDropdownProps) {
  const router = useRouter()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="flex items-center rounded-md px-1 py-2 transition-colors hover:bg-accent"
        aria-label="Switch between Creator Toolkit, Spatial Studio, and spatial.io"
      >
        <ChevronsUpDownIcon className="size-4" />
      </DropdownMenuTrigger>

      <DropdownMenuPortal>
        <DropdownMenuContent align="start">
          {items.map(({ label, href }) => (
            <DropdownMenuItem
              key={href}
              aria-current={activeHref === href ? "page" : undefined}
              className="group grid grid-cols-[1rem_auto] items-center justify-items-start gap-1.5 no-underline aria-current-page:text-blue aria-current-page:data-[highlighted]:text-blue"
              asChild
              onSelect={() => {
                router.push(href)
              }}
            >
              <Link href={href}>
                <CheckIcon className="hidden size-4 group-aria-current-page:block" />
                <Heading className="col-start-2" size="h5">
                  {label}
                </Heading>
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
}
