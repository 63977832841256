import { QueryClient, UseQueryOptions, useQuery } from "@tanstack/react-query"

import { GetUsersWalletRequest, GetUsersWalletResponse } from "@spatialsys/js/sapi/users-v2/wallet"

import { useSapi } from "../use-sapi"

export const GET_USERS_WALLET_QUERY_KEY = "GET_USERS_WALLET"

export const useGetUsersWalletQuery = (
  args: GetUsersWalletRequest,
  options?: UseQueryOptions<GetUsersWalletResponse>
) => {
  const sapiClient = useSapi()
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [GET_USERS_WALLET_QUERY_KEY, args.userId],
    queryFn: () => sapiClient.usersV2.wallet.getUsersWallet(args),
    /** By default, refetch every 5 minutes */
    refetchInterval: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000, // 5min
    ...options,
  })
}

/**
 * Updates usersWallet cache, targeting all cache keys that begin with {@link GET_USERS_WALLET_QUERY_KEY}
 *
 * @param queryClient the react-query client
 * @param mutateFn a function that is called with a wallet. This function should update wallet with a new wallet, if applicable.
 */
export const updateUsersWalletCache = async (
  queryClient: QueryClient,
  mutateFn: (wallet: GetUsersWalletResponse) => GetUsersWalletResponse
) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries([GET_USERS_WALLET_QUERY_KEY])
  const cacheEntries = queryClient.getQueriesData<GetUsersWalletResponse>([GET_USERS_WALLET_QUERY_KEY])

  if (cacheEntries) {
    cacheEntries.forEach(([key]) => {
      queryClient.setQueryData<GetUsersWalletResponse>(key, (prev) => {
        return prev ? { ...mutateFn(prev) } : undefined
      })
    })
  }

  return cacheEntries
}
