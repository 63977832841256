import { VariantProps, cva } from "class-variance-authority"
import Image from "next/image"
import Link from "next/link"
import { useMemo } from "react"

import SpinningCoinGif from "@spatialsys/assets/img/coins/spinning-coin-transparent-bg.gif"
import { useGetUsersWalletQuery } from "@spatialsys/react/query-hooks/users-v2/wallet"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import Config from "@spatialsys/web/config"
import { Heading, cn } from "@spatialsys/web/ui"

export const coinBalanceButtonVariants = cva("no-underline", {
  variants: {
    color: { auto: "text-[inherit]", black: "text-black", white: "text-black sm:text-white" },
    defaultVariants: { color: "white" },
  },
})

type CoinBalanceButtonProps = VariantProps<typeof coinBalanceButtonVariants> & {
  className?: string
  isTransparentBg?: boolean
}

export const CoinBalanceButton = (props: CoinBalanceButtonProps) => {
  const { className, color, isTransparentBg = false } = props
  const { isAuthenticated, isAuthless, isAuthenticatingOrLoggingIn } = useAuthState()
  const userId = useAppContext((context) => context.state.user?.id)

  const usersWalletQuery = useGetUsersWalletQuery(
    { userId: userId!, isSandbox: Config.DEPLOYMENT_ENV !== "production" },
    { enabled: isAuthenticated && !isAuthless && Boolean(userId) }
  )

  const balance = useMemo(() => {
    if (!isAuthenticated || !usersWalletQuery.data) {
      return 0
    }

    return usersWalletQuery.data.spatialCoinsBalance.toLocaleString("en-US")
  }, [isAuthenticated, usersWalletQuery.data])

  return (
    <Link
      className={cn(
        "flex h-10 min-w-10 items-center gap-1 rounded-full border border-foreground/20 bg-transparent py-3 pl-2 pr-4 transition hover:border-foreground",
        coinBalanceButtonVariants({ color, className })
      )}
      href="/coins"
    >
      <Image src={SpinningCoinGif} alt="Spinning Spatial Coin" width={24} height={24} />

      {usersWalletQuery.isInitialLoading || (isAuthenticated && !userId) ? (
        /* 
        Simple, one-off skeleton instead of our Skeleton component from `web/ui`, since the background color and animation there was
        incorrect and difficult to customize
        Based on https://ui.shadcn.com/docs/components/skeleton
        */
        <div
          className={cn(
            "h-3 w-9 animate-pulse rounded-sm bg-gray-100",
            color === "black" && "bg-gray-100 sm:bg-gray-100",
            !isTransparentBg && "dark:bg-gray-400"
          )}
        />
      ) : /** Only display balance if user is authenticated. Else, only display the coin */
      isAuthenticatingOrLoggingIn || (isAuthenticated && !isAuthless) ? (
        <Heading
          className={cn("text-[length:inherit]", color === "white" && "shadow-black/20 sm:text-shadow-sm")}
          asChild
        >
          <span>{balance}</span>
        </Heading>
      ) : null}
    </Link>
  )
}
