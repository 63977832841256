import Link from "next/link"
import { memo, useCallback } from "react"

import { UserData, getSubscriptionFromUser } from "@spatialsys/js/sapi/sapi/users"
import { UserPermissions } from "@spatialsys/js/sapi/types"
import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import { useAppContext } from "@spatialsys/web/app-context"
import { Modals, Selectors } from "@spatialsys/web/app-state"
import Config from "@spatialsys/web/config"
import { cn } from "@spatialsys/web/ui"

import { useIsMobile } from "../../hooks/use-is-mobile"
import { CoinBalanceButton } from "../buy-coins/coin-balance-button/coin-balance-button"
import { ProfileDropdownHeader } from "./profile-dropdown-header"

const MENU_ITEM_CLASSES =
  "w-full rounded-sm px-1.5 normal-case text-left py-1 font-heading text-h5 font-semibold no-underline transition-colors hover:bg-accent hover:text-accent-foreground"

const ProfileMenuLinkItem = memo(function ProfileMenuDropdownMenuLinkItem(
  props: React.ComponentPropsWithoutRef<typeof Link>
) {
  const { children, className, href, ...rest } = props
  return (
    <Link
      href={href}
      className={cn(
        MENU_ITEM_CLASSES,
        "w-full rounded-sm px-1.5 py-1 font-heading text-h5 font-semibold no-underline transition-colors hover:bg-accent hover:text-accent-foreground",
        className
      )}
      {...rest}
    >
      {children}
    </Link>
  )
})

export const ProfileMenuMobile = memo(function ProfileMenuMobile(props: { user: UserData }) {
  const { user } = props
  const { userSocialProfile } = user
  const actions = useAppContext((context) => context.actions)
  const isMobile = useIsMobile()
  const featureFlagsQuery = useGetFeatureFlagsQuery()

  // Derived state
  const hasCurateAccess =
    featureFlagsQuery.data?.featureFlags.feedCurator || featureFlagsQuery.data?.featureFlags.categoryCurator
  const showAdmin = user.permissions.includes(UserPermissions.CAN_ACCESS_ADMIN_PAGE)
  const showCreateOrg = !user.myOrganization?.id
  const isPlus = Boolean(getSubscriptionFromUser(user))
  const showDebug = useAppContext((context) => Selectors.canViewDebugSettings(context.state, Config))

  const logout = useCallback(() => {
    actions.logout({})
  }, [actions])
  const openDebugModal = useCallback(() => {
    actions.openModal({ type: Modals.Debug })
  }, [actions])
  const openPairModal = useCallback(() => {
    actions.openModal({ type: Modals.Pair })
  }, [actions])

  return (
    <div className="grid py-3">
      <ProfileDropdownHeader user={user} />
      <CoinBalanceButton color="auto" isTransparentBg className="p-0 text-h5" />
      <hr className="my-2" />
      <ProfileMenuLinkItem href={`/@${userSocialProfile.username}`}>Profile</ProfileMenuLinkItem>

      <ProfileMenuLinkItem href="/studio">Studio</ProfileMenuLinkItem>
      <ProfileMenuLinkItem href={isPlus ? "/billing" : "/pricing"}>
        {isPlus ? "Billing" : "Publish Spaces"}
      </ProfileMenuLinkItem>
      <ProfileMenuLinkItem href="/content">Content</ProfileMenuLinkItem>
      <ProfileMenuLinkItem href="/integrations">Integrations</ProfileMenuLinkItem>
      <button className={MENU_ITEM_CLASSES} onClick={openPairModal}>
        Pair Headset
      </button>
      <hr className="my-2" />

      {showDebug && (
        <button className={MENU_ITEM_CLASSES} onClick={openDebugModal}>
          Debug
        </button>
      )}
      <ProfileMenuLinkItem href="https://support.spatial.io" target="_blank" rel="noreferrer">
        Support
      </ProfileMenuLinkItem>
      {showAdmin && <ProfileMenuLinkItem href="/admin">Admin</ProfileMenuLinkItem>}
      {hasCurateAccess && <ProfileMenuLinkItem href="/curate">Curate</ProfileMenuLinkItem>}
      {showCreateOrg && <ProfileMenuLinkItem href="/team/create">Create Team</ProfileMenuLinkItem>}
      {isMobile && (
        <ProfileMenuLinkItem href={`${Config.DYNAMIC_LINK_URL}/download`} target="_blank" rel="noreferrer">
          Get Mobile Apps
        </ProfileMenuLinkItem>
      )}

      <button className={MENU_ITEM_CLASSES} onClick={logout}>
        Log out
      </button>
    </div>
  )
})
