import Link from "next/link"
import { memo } from "react"

import { Heading } from "@spatialsys/web/ui"

const links = [
  { label: "Creators", href: "https://toolkit.spatial.io", openInNewTab: true, prefetch: false },
  { label: "Brands", href: "/brands", openInNewTab: false, prefetch: undefined },
  { label: "Pricing", href: "/pricing", openInNewTab: false, prefetch: undefined },
]

export const NavLinks = memo(function NavLinks() {
  return (
    <>
      {links.map((link, i) => (
        <Heading
          asChild
          size="h4"
          key={i}
          className="text-h5 tracking-normal text-[inherit] no-underline transition-colors hover:opacity-70 sm:text-h5 md:text-base xl:text-h4"
        >
          <Link
            href={link.href}
            rel={link.openInNewTab ? "noreferrer" : undefined}
            target={link.openInNewTab ? "_blank" : undefined}
            prefetch={link.prefetch}
          >
            {link.label}
          </Link>
        </Heading>
      ))}
    </>
  )
})
