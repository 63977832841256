import clsx from "clsx"
import dynamic from "next/dynamic"
import Link from "next/link"
import { memo, useCallback, useMemo, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import { useTimeoutFn } from "react-use"

import { ReactComponent as SpatialWordMark } from "@spatialsys/assets/icons/logos/spatial-word-mark.svg"
import { ReactComponent as SpatialLogo } from "@spatialsys/assets/icons/logos/spatial.svg"
import { ReactComponent as StudioWordMark } from "@spatialsys/assets/icons/logos/studio-word-mark.svg"
import { ReactComponent as MoreHorizontal } from "@spatialsys/assets/icons/material-filled/more-horiz.svg"
import { ReactComponent as MenuRounded } from "@spatialsys/assets/icons/material-rounded/menu.svg"
import {
  InteractionName,
  InteractionType,
  TrackedComponent,
  TrackedComponents,
  useTrackInteraction,
} from "@spatialsys/react/analytics"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { TryToAuthenticate } from "@spatialsys/web/core/js/components/auth/try-to-authenticate"
import { useUser } from "@spatialsys/web/core/js/components/user/user-query-hooks"
import { useDynamicLink } from "@spatialsys/web/core/js/hooks/firebase/use-dynamic-link"
import { Button, Container, Heading, cn } from "@spatialsys/web/ui"

import { CoinBalanceButton } from "../buy-coins/coin-balance-button/coin-balance-button"
// The mobile menu is not code-split, it is just a bunch of links
import { ProfileMenuMobile } from "../profile-dropdown-menu/profile-menu-mobile"
import { CtaButton } from "./cta-button"
import { NavLinks } from "./nav-links"
import { NavbarDropdown } from "./navbar-dropdown"
import { SearchButton } from "./search-button"

import classes from "./navbar.module.scss"

export const CREATE_SPACE_QUERY_PARAM_KEY = "create"
export const LOGIN_QUERY_PARAM_KEY = "login"

// The desktop menu is code-split
const importProfileDropdownMenu = () =>
  import(
    /* webpackChunkName: "profile-dropdown-menu" */ "@spatialsys/web/core/js/components/profile-dropdown-menu/profile-dropdown-menu"
  ).then((module) => ({
    default: module.ProfileDropdownMenu,
  }))

const importProfileDropdownMenuWithChallenges = () =>
  import(
    /* webpackChunkName: "profile-dropdown-menu-with-challenges" */ "../profile-dropdown-menu-with-challenges/profile-dropdown-menu-with-challenges"
  ).then((module) => ({
    default: module.ProfileDropdownMenuWithChallenges,
  }))

const ProfileDropdownMenuWithChallenges = dynamic(importProfileDropdownMenuWithChallenges, { ssr: false })

const ProfileDropdownMenu = dynamic(importProfileDropdownMenu, { ssr: false })

/** Navbar color is determined automatically based on the app's color theme. Defaults to "translucent" */
type NavbarColorTheme = "solid" | "transparent" | "translucent"

type NavbarProps = {
  /** Applies negative top margin in order to hide space reserved for the navbar */
  applyNegativeMarginTop?: boolean
  color?: NavbarColorTheme
  /** Disables the createSpace modal. Clicking "Create a Space" button pushes to the current page (no-op) */
  disableCreateSpaceModal?: boolean
  /** If true, hides search. Defaults to false */
  hideSearch?: boolean
  hideWordInLogo?: boolean
  isStudio?: boolean
}

/**
 * App-wide navbar rendered at the top of the page. Can be made transparent using the `color` prop,
 * otherwise defaults to the the color scheme based on the current theme.
 * Responsive: renders a mobile version with hamburger menu on small displays.
 */
export function Navbar(props: NavbarProps) {
  const { applyNegativeMarginTop, color = "translucent" } = props
  // for mobile only. wide screens have menu always showing
  const [isMenuOpen, setIsMenuOpen] = useBoolean(false)

  return (
    <TrackedComponent
      id={TrackedComponents.Navbar}
      as="header"
      htmlId="headerNav"
      className={cn(
        "sticky top-0 z-overlay grid h-[--navbar-height] w-full justify-items-center border-none bg-background/80 text-foreground backdrop-blur-lg transition-colors sm:bg-background/70",
        // Transparent only on larger screens
        color === "transparent" && "sm:border-none sm:bg-transparent sm:backdrop-blur-none",
        color === "solid" && "border-b bg-background backdrop-blur-none sm:bg-background",
        isMenuOpen && "bg-background",
        applyNegativeMarginTop && "mt-[calc(-1_*_var(--navbar-height))]",
        classes.navbar
      )}
    >
      <NavbarInner
        {...props}
        isMenuOpen={isMenuOpen}
        closeMenu={setIsMenuOpen.setFalse}
        toggleMenuOpen={setIsMenuOpen.toggle}
      />
    </TrackedComponent>
  )
}

type NavbarInnerProps = NavbarProps & {
  isMenuOpen: boolean
  toggleMenuOpen: () => void
  closeMenu: () => void
}

const NavbarInner = memo(function NavbarInner(props: NavbarInnerProps) {
  const {
    disableCreateSpaceModal,
    color = "translucent",
    hideSearch,
    isMenuOpen,
    isStudio,
    closeMenu,
    toggleMenuOpen,
  } = props
  const trackInteraction = useTrackInteraction()
  const { isAuthenticated, isAuthless } = useAuthState()
  const actions = useAppContext((context) => context.actions)
  const showOldHomepage = useAppContext((context) => context.state.showOldHomepage)
  const flagsQuery = useGetFeatureFlagsQuery()

  const webPlatformChallenges = flagsQuery.data?.featureFlags.webPlatformChallenges
  const isNewAuthlessHomepage = flagsQuery.data?.featureFlags.newAuthlessHomepage

  const shouldHideAvatarStoreLinkAndSearch = useMemo(
    () => (!isAuthenticated || isAuthless) && isNewAuthlessHomepage,
    [isAuthenticated, isAuthless, isNewAuthlessHomepage]
  )

  const dynamicLink = useDynamicLink()

  const { user } = useUser()
  const menuRef = useRef<HTMLDivElement>(null)

  // Pre-load the lazy-loaded Profile Menu so it's immediately available when user signs in
  useTimeoutFn(importProfileDropdownMenu, 200)

  const openInApp = useCallback(() => {
    trackInteraction({ name: InteractionName.OpenInApp, type: InteractionType.Click })
  }, [trackInteraction])

  const openCategoryMenu = useCallback(() => {
    actions.setCategoryMenuOpen()
  }, [actions])

  const onShowNewHomepage = useCallback(() => {
    actions.showNewHomepage()
  }, [actions])

  return (
    <TryToAuthenticate>
      <Container
        asChild
        className={cn("z-50 flex h-full items-center justify-between", isMenuOpen && classes.menuOpen)}
      >
        <div>
          <div
            className={cn(
              "z-30 grid h-full w-full flex-1 grid-flow-col items-center justify-between bg-transparent",
              color === "solid" && "bg-background sm:bg-transparent"
            )}
          >
            <div className="flex flex-row items-center whitespace-nowrap">
              {isAuthenticated && !isAuthless && (
                <button
                  className={cn("flex pr-3 hover:opacity-70", color === "transparent" && "sm:text-white")}
                  onClick={openCategoryMenu}
                >
                  <MenuRounded />
                </button>
              )}
              {/* Spatial Logo */}
              <Link
                href={isStudio ? "/studio" : "/"}
                className={cn(
                  "flex w-auto shrink-0 items-center text-foreground no-underline transition hover:opacity-70",
                  color === "transparent" && "sm:text-white"
                )}
                aria-label="Home"
              >
                {props.hideWordInLogo ? (
                  <SpatialLogo className="h-8 w-auto" />
                ) : (
                  <>
                    <SpatialLogo className="h-8 w-auto xs:hidden" />
                    {isStudio ? (
                      <StudioWordMark className="hidden h-8 w-auto xs:block" />
                    ) : (
                      <SpatialWordMark className="hidden h-8 w-auto xs:block" />
                    )}
                  </>
                )}
                <span className="sr-only">Spatial home page</span>
                {isStudio && <NavbarDropdown activeHref="/studio" />}
              </Link>
              <nav
                className={cn(
                  "hidden w-fit grid-flow-col gap-2 px-2 sm:grid sm:gap-4 sm:px-4 md:gap-6 md:px-6 xl:gap-8 xl:px-8",
                  color === "transparent" && "text-white"
                )}
              >
                {(!isAuthenticated || isAuthless) && (
                  <>
                    <NavLinks />

                    {showOldHomepage && (
                      <button onClick={onShowNewHomepage}>
                        <Heading
                          size="h4"
                          className="text-h5 tracking-normal text-[inherit] no-underline transition-colors hover:opacity-70 sm:text-h5 md:text-base xl:text-h4"
                        >
                          Learn More
                        </Heading>
                      </button>
                    )}
                  </>
                )}
                {!isStudio && !shouldHideAvatarStoreLinkAndSearch && (
                  <Heading
                    asChild
                    size="h5"
                    className="text-h5 tracking-normal text-[inherit] no-underline transition-colors hover:opacity-70 md:text-base xl:text-h4"
                  >
                    <Link href="/store">Avatar Store</Link>
                  </Heading>
                )}
              </nav>
            </div>
          </div>

          {/* Right side of navbar */}
          <div
            className={cn(
              "z-30 grid grid-flow-col items-center gap-2 whitespace-nowrap bg-transparent text-foreground sm:gap-3 md:gap-4 lg:gap-4 xl:gap-4",
              color === "solid" && "bg-background sm:bg-transparent"
            )}
          >
            {!hideSearch && !shouldHideAvatarStoreLinkAndSearch && (
              <div className={clsx(color === "transparent" && "sm:text-white")}>
                <SearchButton />
              </div>
            )}
            {isAuthenticated && !isAuthless && (
              <div className={cn(color === "transparent" ? "sm:text-white" : "text-foreground", "hidden sm:block")}>
                <CoinBalanceButton color="auto" isTransparentBg={color === "transparent"} />
              </div>
            )}
            <Button
              color="primary"
              size="sm"
              as={Link}
              href={dynamicLink.href}
              onClick={openInApp}
              className="sm:hidden"
            >
              Use App
            </Button>

            {/* Ideally ProfileDropdownMenu could suspend on user, but this appears to cause weird React hydration issues */}
            {isAuthenticated && !isAuthless && (
              <div className="hidden sm:flex">
                {user ? (
                  webPlatformChallenges ? (
                    <ProfileDropdownMenuWithChallenges user={user} />
                  ) : (
                    <ProfileDropdownMenu user={user} />
                  )
                ) : (
                  <div
                    className={cn(
                      "h-8 w-8 animate-pulse rounded-full bg-gray-100",
                      color !== "transparent" && "dark:bg-gray-400"
                    )}
                  />
                )}
              </div>
            )}
            <div className="hidden sm:flex sm:items-center">
              <CtaButton disableCreateSpaceModal={disableCreateSpaceModal} />
            </div>
            {/* Mobile-only: hamburger menu */}
            <button className="flex items-center text-foreground sm:hidden" onClick={toggleMenuOpen} aria-label="Menu">
              <MoreHorizontal />
            </button>
          </div>

          <div
            className={cn(
              "pointer-events-none absolute left-0 top-0 z-10 mt-[--navbar-height] h-screen w-full bg-black/0 transition-colors sm:hidden",
              isMenuOpen && "pointer-events-auto bg-black/50"
            )}
            onClick={closeMenu}
          />

          {/* Mobile menu */}
          <CSSTransition
            in={isMenuOpen}
            mountOnEnter
            unmountOnExit
            classNames={{
              enter: classes.menuEnter,
              enterActive: classes.menuEnterActive,
              enterDone: classes.menuEnterDone,
              exit: classes.menuExit,
              exitActive: classes.menuExitActive,
              exitDone: classes.menuExitDone,
            }}
            timeout={400}
            nodeRef={menuRef}
          >
            <Container
              ref={menuRef}
              className={cn(
                "absolute left-0 right-0 top-full z-20 flex flex-col gap-4 bg-background pb-5 sm:hidden",
                classes.menu,
                isMenuOpen && classes.menuOpen
              )}
            >
              {user && !isAuthless ? (
                <ProfileMenuMobile user={user} />
              ) : (
                <nav className="grid grid-flow-row gap-4 pt-2">
                  <NavLinks />
                </nav>
              )}
              <CtaButton disableCreateSpaceModal={disableCreateSpaceModal} />
            </Container>
          </CSSTransition>
        </div>
      </Container>
    </TryToAuthenticate>
  )
})
