import Link from "next/link"
import { memo } from "react"

import { ReactComponent as SearchIcon } from "@spatialsys/assets/icons/material-filled/search.svg"

const searchButtonClasses =
  "transition-colors flex items-center justify-center text-[inherit] hover:border-foreground flex justify-center lg:justify-start no-underline border border-foreground/20 min-w-10 h-10 rounded-full px-0 lg:pl-3 lg:min-w-44 xl:min-w-80 gap-1"

/**
 * Pushes to `/search` if user is authenticated, otherwise opens the login modal.
 */
export const SearchButton = memo(function SearchButton() {
  return (
    <Link href="/search/spaces" className={searchButtonClasses} aria-label="Search">
      <SearchIcon className="lg:h-4" />
      <span className="hidden text-sm lg:block">Search</span>
    </Link>
  )
})
