import { cn } from "@spatialsys/web/ui"

type FadeOnHoverProps = {
  className?: string
  overlay: React.ReactNode
  children: React.ReactNode
}

export const FadeOnHover = ({ className, children, overlay }: FadeOnHoverProps) => {
  return (
    <div className={cn("group relative h-full", className)}>
      <div className="h-full transition-all group-hover:blur-xs group-hover:brightness-75">{children}</div>
      <div className="align-center absolute inset-0 flex items-center justify-center opacity-0 transition-all duration-300 group-hover:opacity-100">
        {overlay}
      </div>
    </div>
  )
}
