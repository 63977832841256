import { useRouter } from "next/router"
import { useMemo } from "react"

import { SPATIAL_UID_URL_PARAM_NAME } from "@spatialsys/js/util/constants"
import { useAppContext } from "@spatialsys/web/app-context"
import Config from "@spatialsys/web/config"

const {
  CANONICAL_URL_ORIGIN,
  DYNAMIC_LINK_URL,
  ANDROID_PACKAGE_NAME,
  IOS_BUNDLE_ID,
  IOS_APP_STORE_ID,
  DEPLOYMENT_ENV,
} = Config

/**
 * Creates a Firebase dynamic link for the current route according to
 * https://firebase.google.com/docs/dynamic-links/create-manually. Passes on the current path,
 * including the query string, and adds on the spatialUid as query parameter so that the mobile
 * app can attribute the mobile user back to the user created on the web.
 *
 * Firebase will detect if the user has the app installed, and if so,
 * open the app directly and pass in the route that the user is currently on.
 * Otherwise, Firebase will open the corresponding app store based on their mobile device.
 * On desktop, the webpage will be opened like any other URL on the web app.
 */
export const useDynamicLink = () => {
  const { asPath } = useRouter()

  const spatialUid = useAppContext((context) => context.state.spatialUid)

  return useMemo(() => {
    const url = new URL(DYNAMIC_LINK_URL)

    const deeplink = new URL(CANONICAL_URL_ORIGIN + asPath)
    // Add on the spatial uid to the link so that mobile can attribute the user back to the web.
    if (spatialUid) deeplink.searchParams.set(SPATIAL_UID_URL_PARAM_NAME, spatialUid)
    url.searchParams.set("link", deeplink.toString())

    url.searchParams.set("apn", ANDROID_PACKAGE_NAME)
    url.searchParams.set("ibi", IOS_BUNDLE_ID)
    url.searchParams.set("isi", IOS_APP_STORE_ID)

    if (DEPLOYMENT_ENV !== "production") {
      // Fallback links for non-prod environments, use staging apps instead of app store redirect
      url.searchParams.set("ifl", "https://testflight.apple.com/join/YAjvlHe9")
      url.searchParams.set(
        "afl",
        "https://docs.google.com/document/d/1qCtIwYwkf0Xw6HR4x1uCCHEctdYB1JxbyRDhJN7HuZE/edit"
      )
    }

    return url
  }, [asPath, spatialUid])
}
